import { t } from 'i18next';
import React, { ChangeEvent, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

//use route parameters to have country and quiz Id in the url:
//https://stackoverflow.com/questions/45898789/react-router-pass-param-to-component
//https://betterprogramming.pub/how-to-pass-multiple-route-parameters-in-a-react-url-path-4b919de0abbe


const Start = () => {
    const navigate = useNavigate();
    
    const [input, setInput] = useState<string>('');
    const [username, setUsername] = useState<string>('admin');
    const [questionNumber, setQuestionNumber] = useState<number>(0);

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);
    };

    return <>
    <div className="d-grid gap-2">
        {/* {t('enterId')} */}
        <h1>Enter Id</h1>
        <Form.Control type="text" placeholder="Id" value={input} onChange={onInputChange} />
        {/*  <input placeholder='Id' value={input} onChange={onInputChange}></input> */}
        <Button variant="primary" size="lg"
                    onClick={() => navigate(`/quiz/${input}/${questionNumber}/${username}`)}>Start quiz</Button>
    </div>
    </>
}

export default Start;
