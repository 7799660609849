import React, { ChangeEvent, FormEvent, useState } from "react";
import { Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../css/Login.css";
import axios from "axios";
import config from '../../../config/default';

type Props = {
    email: string,
    passwordProp: string,
	hasError: boolean,
	errorMessage: string
}

const Login = ({email, passwordProp, hasError, errorMessage}: Props) => {
const [state, setstate] = useState<string>();
const [mail, setMail] = useState<string>(email);
const [password, setPassword] = useState<string>(passwordProp);
const [error, setError] = useState<boolean>(hasError);
const [message, setMessage] = useState<string>(errorMessage);
const [loading, setLoading] = useState<boolean>(false);

    const login = () => {
        setLoading(true);
        console.log("Loggin in")
        console.log(process.env.REACT_APP_BACKEND_API);
    //    console.log(config.SERVER_URL);
    //    console.log(config.get('accessTokenTtl'));
		//axios.post(config.SERVER_URL + "/authenticate", {
        axios.post(process.env.REACT_APP_BACKEND_API + "/users/login", {
			email: mail,
			password: password,

		},
			{
				headers: {
					'Content-Type': 'application/json',
					// 'Authorization': 'Bearer ' + cookies.get('token'),
				}
			}
		)
        .then((rsp) => {
            
console.log("TESTING3");
            if (!rsp.data.status) {
                
console.log("TESTING4");
                console.log(rsp.data);
                // this.setState({
                // 	errorMessage: rsp.data.message,
                // 	harError: true
                // })
console.log("TESTING2");

            }
            else {
console.log("TESTING");
            }
            
            setLoading(false);
        });
	}

	// const onChange = (e: string) => {
	// 	setState(e.target.value)
	// }

	const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
		setMail(event.target.value)
	}
    const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value)
	}   



	const onSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()
        console.log("CHECKHCEKCHCEKCHCEKCHEK")
		login();
        
console.log("TESTING");
	}

    const validateForm = () => {
        return loading || mail != '' || password != '';
    }

    return (
    <div className="container">
        <Form noValidate onSubmit={onSubmit}>
            <h1 className="h3 mb-3 front-weight-normal">Please sign in</h1>
            {/* <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          /> */}
             <Form.Group>
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="name@example.com" onChange={onChangeEmail} value={mail} />
            </Form.Group>
             <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" onChange={onChangePassword} value={password} />
            </Form.Group>
            <Button  variant="primary" size="lg" disabled={validateForm()} onClick={login}>
            {loading && (
                <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
            </Button>
            <div className="form-group">
                <div className="col-md-12 control">
                    <label htmlFor="donthaveaccount">Don't have an account? </label>
                    <a href="/register">
                        Sign Up Here
                    </a>
                </div>
            </div>
            {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
        </Form>
</div>)
}

export default Login;