import { Route, Routes } from 'react-router-dom';
import React from 'react';
import QuizComponent from './quizComponent';

function Quiz() {
  return (
    <>
      {/* <nav>
          <Link to="me">My Profile</Link>
        </nav> */}

      <Routes>
        <Route path=":quizid/:questionnumber/:username" element={<QuizComponent />} />
      </Routes>
    </>
  );
}

export default Quiz;
