import React, { useEffect, useState } from 'react'
import { Navigate, useParams, useNavigate} from 'react-router-dom';
// import quiz from '../../../JsonData/QuizInJson.json';
import { AnsweredQuestion, QuestionType, Quiz, QuizData, QuizDataAlternatives } from '../../../interfaces/quiz';
import LandingPage from '../landingPage';
import CountDownTimer from '../timer/countDownTimer';
import QuizQuestion from './quizQuestion';
import { Cookies,ReactCookieProps, useCookies } from 'react-cookie';
import { getCookie, setCookie } from 'typescript-cookie'
import dotenv from 'dotenv';
import QuizSummary from '../summary/quizSummary';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';

//use route parameters to have country and quiz Id in the url:
//https://stackoverflow.com/questions/45898789/react-router-pass-param-to-component
//https://betterprogramming.pub/how-to-pass-multiple-route-parameters-in-a-react-url-path-4b919de0abbe

const QuizComponent = () => {
    const [quizPosition, setQuizPosition] = useState<number>(1);
    const [questionAnswered, setQuestionAnswered] = useState<boolean>(false);
    const params = useParams();
    const { quizid } = useParams<"quizid">();
    const { username } = useParams<"username">();
    const { questionnumber } = useParams<"questionnumber">();
    // const [cookies, setCookie1, removeCookie1] = useCookies(['tipwalk-answered-questions']);
	const [loading, setLoading] = useState<boolean>(true);
	const [cookies, setCookie] = useCookies(['token']);
    
    const [quizData, setQuizData] = useState<QuizData[]>();
    const [quiz, setQuiz] = useState<Quiz>();
    
    // useEffect(() => {
    //     // fetch("http://localhost:5000/quiz") 5001 as port?!?!?!?!?!
    //     // .then(res => setQuiz( res ));
    //     
    // });

    const [value, setValue] = useState('');

    const mapLocalStorageAnswers = (answersinJson:string | null) => {
        
        if(answersinJson == null){
            return [];
        }
        const answers: AnsweredQuestion[] = JSON.parse(answersinJson)
        return answers;
    }
    const [answerToQuestions, setAnswerToQuestions] = useState<AnsweredQuestion[]>(mapLocalStorageAnswers(localStorage.getItem('answered')));

    const checkQuizDone = () => {
        if(localStorage.getItem('QuizCompleted') !== null){
            if(mapLocalStorageAnswers(localStorage.getItem('answered'))){
                setAnswerToQuestions(mapLocalStorageAnswers(localStorage.getItem('answered')));
            }
            return true
        }
        return false;
    }
    const [quizDone, setQuizDone] = useState<boolean>(checkQuizDone);

    //TODO: This works, to process env. variables. Use this to get data from BE
    // console.log(process.env.REACT_APP_API_ENDPOINT);
    // console.log(process.env.REACT_APP_BACKEND_API);
    
    useEffect(() => 
    
        {
            if(loading && quizid !== undefined){
                
            setLoading(true);
            axios.get(process.env.REACT_APP_BACKEND_API + `/quiz?id=${quizid}`, //Change this to specific search criterias
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + cookies.token,
                }
            })
            .then((rsp) => {
                if (!rsp.data) {
                    
                    toast.error(rsp.data.message);
                    setLoading(false);
                }
                else {
                    toast.success(rsp.data);
                    const quizMatch : Quiz[] = rsp.data.message;
                    setQuiz(quizMatch[0]);
                    console.log(quizMatch);
                    const quizDataMatch = quizMatch[0].QuizData;
                    setQuizData(quizDataMatch);
                    setLoading(false);
                }
                
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                
                toast.error("error");
                setLoading(false);
            });
        }
    }
        , [value]
    )

    // const quizData: QuizData[] | undefined = quiz?.map(d => {return {
    //                         Id: d.Id,
    //                         Alternatives: d.Alternatives.map(a => {return {Alternative: a.Alternative, Answer: a.Answer}}), 
    //                         Category: d.Category,
    //                         Question: d.Question,
    //                         QuestionNo: d.QuestionNo,
    //                         IsQuestionAnswered: false,
    //                         YourAnswerAlternative: "",
    //                         isAnswerCorrect: false,
    //                         PicturePath: d.PicturePath,
    //                         Type: d.Type
    //     }});
    
    // const quizWithData : Quiz = 
    // {
    //     Id: quiz.Id,
    //     City:quiz.City,
    //     Country: quiz.Country,
    //     Language: quiz.Language,
    //     Location: quiz.Location,
    //     QuizData: quizData,
    //     QuizName: quiz.QuizName
    // }

    //Verify the different parameters
    // useEffect(() => {

    //     //get localstorage or cookie to fetch if they where logged in and have been paying for it.
    //     //Need user

    // })
    
    const showNextQuestion = () => {
        setQuizPosition(quizPosition + 1);
        setQuestionAnswered(true);

        const questionToSend = quizData?.find(q => questionnumber !== undefined && parseInt(questionnumber) as number === q.QuestionNo && !q.IsQuestionAnswered);
        
        // setCookie1("tipwalk-answered-questions", questionToSend, {path: "/", });

        // const cookie = cookies['tipwalk-answered-questions'];
        // console.log("cookie " + cookie);

        // setCookie("Test", questionToSend?.QuestionNo, {path: "/"});
        // console.log("Test " + getCookie('Test'));
    }

    const answeredAlternative = (alternative: QuizDataAlternatives) => {

        if(quizData == undefined || quizid == undefined) return;
        const questionToAnswered = quizData.find(q => questionnumber !== undefined && parseInt(questionnumber) as number === q.QuestionNo);
        if(questionToAnswered == undefined) return;

        const answeredQuestion : AnsweredQuestion = {
            QuizId: quizid,
            QuestionId: questionToAnswered.Id,
            QuestionNo: questionToAnswered.QuestionNo,
            Category: questionToAnswered.Category,
            Question: questionToAnswered.Question,
            IsQuestionAnswered: true,
            YourAnswerAlternative: alternative,
            Alternatives: questionToAnswered.Alternatives,
            Type: questionToAnswered.Type,
            IsAnswerCorrect: alternative.Answer == questionToAnswered.Alternatives.find(q => q.CorrectAnswer)?.Answer ? true : false,
            PicturePath: questionToAnswered.PicturePath,
            City:quiz?.City ?? "",
            Country:quiz?.Country ?? "",
            Location:quiz?.Location ?? "",
            QuizName: quiz?.QuizName ?? ""
        }

        setAnswerToQuestions(prevState => [...prevState, answeredQuestion]);
        const storeQuestions: AnsweredQuestion[] = [ answeredQuestion ];   

        if(localStorage.getItem('answered') === null){
            
            localStorage.setItem("answered", JSON.stringify(storeQuestions));
            
        }
        else {
            const items = JSON.parse(localStorage.getItem('answered') ?? "");

            if(items === "") {
                //Add to localstorage, first item
                localStorage.setItem("answered", JSON.stringify(storeQuestions));
            }
            else {
                
                const objects = items as AnsweredQuestion[];
                const newAnsweredQuestions = storeQuestions.concat(objects);
                //Clear localstorage and set the new list as localstorage
                localStorage.removeItem("answered");

                localStorage.setItem("answered", JSON.stringify(storeQuestions.concat(objects)));

                if(newAnsweredQuestions.length === quizData.length){
                    console.log("You have ended the quiz");
                    setQuizDone(true);
                    localStorage.setItem("QuizCompleted", new Date().getTime().toString());
                    localStorage.setItem("YourCompletedQuiz", localStorage.getItem('answered') ?? "");

                    // localStorage.removeItem("answered");
                    //TODO: send to BE



                }
            }
        }    }

    const endQuestion = () => {
        //set question to answered

        //end the question, with no option to go back.
    }

    const questionToSend = quizData?.find(q => questionnumber !== undefined && parseInt(questionnumber) as number === q.QuestionNo && !q.IsQuestionAnswered);

    let navigate = useNavigate(); 

    return (
    <>

        {/* <CountDownTimer hours={0} minutes={0} seconds={10} endQuestion={endQuestion}/> */}
        {loading ? 
            <>
                loading question...
                <CircularProgress />
            </>
            :
            <>
                {quizDone && quizid !== undefined ?  
                
                    navigate(`/summary/${quizid}`)
                // <Navigate to="/summary/" />
                // <QuizSummary quizId={quizid} answeredQuestions={answerToQuestions} />
                :
                    questionnumber !== undefined && parseInt(questionnumber) as number === questionToSend?.QuestionNo && !answerToQuestions.find(a => a.QuestionNo === questionToSend.QuestionNo)
                        ?
                            <>
                                {quizData && quizData.length >= quizPosition 
                                ?   
                                    <div>Question {quizPosition} out of {quizData.length} </div>
                                :      
                                    <></>
                                }
                                <br></br>
                                <QuizQuestion quizQuestion={questionToSend} 
                                            showNextQuestion={showNextQuestion}
                                            quizNumber= {quizPosition}
                                            answeredAlternative={answeredAlternative} />
                            </>
                        :   
                            <LandingPage/>
                    
                }
            </>
        }
    </>);
}

export default QuizComponent;
