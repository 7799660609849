
import React from 'react'
import { QuestionType, QuizData, QuizDataAlternatives } from "../../../interfaces/quiz";
import QuizAnswerButton from "./quizQuestionButton";
import { Container} from "react-bootstrap";
import "./quiz.css";

type Props = {quizQuestion: QuizData | undefined,
    showNextQuestion: () => void,
    quizNumber: number,
    answeredAlternative: (alternative: QuizDataAlternatives) => void
}

const QuizQuestion = ({quizQuestion, showNextQuestion, quizNumber, answeredAlternative} : Props) => {
    return (
        quizQuestion ? 
        <main className="quiz-question">
            <section className="quiz-question">
              <p>{quizQuestion.Question}</p>
            </section>
            {
                quizQuestion.Type === QuestionType.Picture && quizQuestion.PicturePath !== undefined ? 
                    <section>
                        {/* TODO: FIX THIS with picture */}
                    <img className="quiz-question" src={require("../../../JsonData/Square.png")} alt=""/>
                    </section>
                    : <></>
            }
            <Container>
                        <QuizAnswerButton alternatives={quizQuestion.Alternatives} quizNumber={quizNumber} showNextQuestion={showNextQuestion} answeredAlternative={answeredAlternative}/>
            </Container>
          </main>
          : <>Hittade ingen fråga. summera frågor</>
          )
  }

  export default QuizQuestion;