import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QrReader from "react-qr-scanner";
import { truncateSync } from "fs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCameraRotate, faTimes } from '@fortawesome/free-solid-svg-icons'
import "../css/ImageOverlay.css";
import Home from "../Home";

type TestState = {
  delay: number | undefined,
  result: string | undefined
}

const QRScan = () => {
  // const state = {
  //   delay: 100,
  //   result: "No result"
  // };

  const [state, setState] = useState<TestState>({delay: 300, result: undefined});  
  const [user, setUser] = useState<string>("");
  const [quizId, setQuizId] = useState<string>("");
  const [questionNumber, setQuestionId] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [validatedUrl, setValidatedUrl] = useState<boolean>(false);
  const [cameraFacingMode, setCameraFacingMode] = useState<"rear"| "front" | undefined>();

  const navigate = useNavigate();

  const validateUrl = (url: string) :boolean => {

    const url_array = url.split('/');

    const user = url.substring(url.lastIndexOf("/") + 1, url.length);
    //Or
    // const user2 = url_array[5];
    setUser(user);
    //validate user    
    // const restNoUser = url.substring(0, url.lastIndexOf("/") + 1);
    // const restNoUserNoSlash = restNoUser.slice(0, -1);
    // const question = restNoUserNoSlash.substring(restNoUserNoSlash.lastIndexOf("/") + 1, restNoUserNoSlash.length);

    // const restNoUserNoSlashNoQuestion = restNoUserNoSlash.substring(0, restNoUserNoSlash.lastIndexOf("/") + 1);
    // const restNoUserNoQuestionNoSlash = restNoUserNoSlashNoQuestion.slice(0, -1);
    // const restNoUserOrQuestion = restNoUserNoQuestionNoSlash.substring(0, restNoUserNoQuestionNoSlash.lastIndexOf("/") + 1);
    // const quiz = restNoUserOrQuestion.substring(restNoUserOrQuestion.lastIndexOf("/") + 1, restNoUserOrQuestion.length);

    // console.log(restNoUser);
    // console.log(restNoUserNoSlash);
    // console.log(question);
    // console.log(restNoUserNoSlashNoQuestion);
    // console.log(restNoUserNoQuestionNoSlash);
    // console.log(restNoUserOrQuestion);
    // console.log(quiz);

    const quizId = url_array[4];
    setQuizId(quizId);
    const questionId = url_array[5];
    setQuestionId(questionId);
    setValidatedUrl(true);
    return true;
  }
  
  const handleScan = (data: string | undefined) => {
    if(data === undefined || data === null)
    {
      return;
    }
    else
    {
      setState({
        result: data,
        delay: state?.delay
      });
      // const url_array = data.split('/');

      console.log("Goto validate data:" + data);
      //Validate the QR code
      if(validateUrl(data)){
        console.log("validated");
      }

      if(data === "http://www.tipwalk.se"){
        //validate the first part
        
        //if url like http://www.tipwalk.se/quiz/123/admin, then validate the first part, like 123 that it is a number, 
          //then the admin, and go to a middleware maybe and say that the user can be authenticated ot validated
        //
        navigate("/validate-code", {state:{code: data}});
      }


      else if(data === "http://localhost/"){
        //validate the first part
        
        //if url like http://www.tipwalk.se/quiz/123/admin, then validate the first part, like 123 that it is a number, 
          //then the admin, and go to a middleware maybe and say that the user can be authenticated ot validated
        //
        navigate("/validate-code", {state:{code: data}});
      
      }
      
      if(validatedUrl){
        navigate(`/quiz/${quizId}/${questionNumber}/${user}`);
      }
      // <Route path="/validate-code" element={<ValidateQrCode code={data}/>}/>



      //Go to the correct site + tipwalk + user prompt to login or buy the tipwalk
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  const reverseCameraFacingModeIfPossible = () => {
    setCameraFacingMode(cameraFacingMode === "rear" ? "front" : "rear")
  }
  
  const closeQrScanner = () => {
    navigate("/home");
  }

    return (
      <div>
        <a className="block-icon">
        <QrReader
          delay={state?.delay}
          style={{ width: '100%' }}
          onError={handleError}
          onScan={handleScan}
          facingMode={cameraFacingMode}
        />
        <FontAwesomeIcon
            color="grey"
            className="fa-stack the-wrapper icon-tag-cross"
            icon={faTimes}
            onClick={closeQrScanner}
          />
          <FontAwesomeIcon
              color="grey"
              className="fa-regular the-wrapper icon-tag-reverse"
              icon={faCameraRotate}
              onClick={reverseCameraFacingModeIfPossible}
            />
        </a>
      </div>
    );
}

export default QRScan;
