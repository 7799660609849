import { t } from 'i18next';
import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

//use route parameters to have country and quiz Id in the url:
//https://stackoverflow.com/questions/45898789/react-router-pass-param-to-component
//https://betterprogramming.pub/how-to-pass-multiple-route-parameters-in-a-react-url-path-4b919de0abbe

type Props = {
    id: number
}

const LandingPage = () => {
    
    const navigate = useNavigate();
    const params = useParams();
    // console.log(params?.quizid);

    //do validations here of the Id

    //get the correct quiz

    //button to start, login, register or pay for the quiz
    return (
    <>
        <div className="d-grid gap-2">
            <div>
                Scan the next question {params?.quizid}
            </div>
            <Button variant="primary" size="lg" onClick={() => navigate("/scan")}>
                {/* {t('input.scanQrCode')} */}
                Scan QR code
            </Button>
        </div>
    </>
    );
}

export default LandingPage;

//https://jitter.video/ for animations
//https://www.canva.com/ or other free logo maker like https://www.tailorbrands.com/l