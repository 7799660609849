import React from 'react'
import { useNavigate } from "react-router-dom";
import {Button} from 'react-bootstrap';

const Home = (() => {
    const navigate = useNavigate();

    return (        
    <div className="container">
        <div className="d-grid gap-2">
            <h1 className="text-center">Välkommen till tipwalk</h1>
            <Button variant="primary" size="lg"
                onClick={() => navigate("/scan")}>Scanna QR kod</Button>
            {/* <button type="submit"
                className="btn btn-lg btn-primary btn-block"
                onClick={() => navigate("/scan")}>Scanna QR kod</button> */}
            <h1 className="text-center">Ingen skanning? Registrera Id manuellt</h1>
            <Button variant="secondary" size="lg"
                onClick={() => navigate("/start")}>Registrera Id</Button>

            
            <h2 className="text-center">Eller välj din walk via kartan</h2>
            {/* <button onClick={() => navigate("/map")}>Gå till karta</button> */}
        </div>
    </div>
    )
});

export default Home;