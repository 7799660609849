import react, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { AnsweredQuestion, Quiz } from '../../../interfaces/quiz';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Paper, Card, TableBody, TableContainer, TableHead } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./summary.css";
import { faCheck, faCross, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

type Props = {quizId: string, answeredQuestions : AnsweredQuestion[]}

const QuizSummary = ({quizId, answeredQuestions} : Props) => {
    const idOfQuiz = quizId;
    const [quiz, setQuiz] = useState<AnsweredQuestion[]>(answeredQuestions);
    const [value, setValue] = useState('');
    let navigate = useNavigate(); 
    
    const mapLocalStorageAnswers = (answersinJson:string | null) => {
        
        if(answersinJson == null){
            return [];
        }
        const answers: AnsweredQuestion[] = JSON.parse(answersinJson)
        return answers;
    }

    useEffect(() => 
        {   
            if(!quiz.length){
                if(localStorage.getItem('QuizCompleted') !== null){
                    if(mapLocalStorageAnswers(localStorage.getItem('answered'))){
                        setQuiz(mapLocalStorageAnswers(localStorage.getItem('answered')));
                    }
                    else{
                        navigate(`/`)
                    }
                }
                else{
                    navigate(`/`)
                }
            }
        }
        , [value]
    );

    return (
    <div>
        {`Thanks for participating!`}
        <br></br>
        {`Summary of Quiz ${idOfQuiz}`}
        <br></br>
      <TableContainer component={Card}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="right">Number</TableCell>
                    <TableCell align="left">Question</TableCell>
                    <TableCell align="left">Your answer</TableCell>
                    <TableCell align="left">Correct answer</TableCell>
                    <TableCell align="left">Category</TableCell>
                </TableRow>
            </TableHead>
            
            <TableBody>
            {quiz?.sort((a, b) => a.QuestionNo > b.QuestionNo ? 1 : -1).map((q) =>(
                <TableRow key={q.QuestionId} >
                     {/* className={q.IsAnswerCorrect ? 'row-style-correct' : 'row-style-error'} */}
                    <TableCell align="right" >{q.IsAnswerCorrect ? 
                                                <FontAwesomeIcon icon={faThumbsUp} color="green" /> : 
                                                <FontAwesomeIcon icon={faThumbsDown} color="red" /> } 
                           {" "} {q.QuestionNo}
                    </TableCell>
                    <TableCell>{q.Question}</TableCell>
                    <TableCell>{q.YourAnswerAlternative.Alternative} 
                                {". "} 
                                {q.YourAnswerAlternative.Answer}</TableCell>
                    <TableCell>{q.Alternatives.filter(alternative => alternative.CorrectAnswer == true)[0].Alternative}
                                {". "} 
                                {q.Alternatives.filter(alternative => alternative.CorrectAnswer == true)[0].Answer}</TableCell>
                    <TableCell>{q.Category}</TableCell>
                </TableRow>)
                    )
            }
            </TableBody>
            </Table>
        </TableContainer>
        
        {`You got ${quiz.filter(q => q.IsAnswerCorrect).length} correct answer(s)`}
    </div>);
}

export default QuizSummary;


  // <div >
                    
                //     <form> 
                //         <div>
                //             <label>Id</label>
                //             <textarea readOnly={true} value={q.QuestionId}/> 
                //         </div>
                //         <div>
                //             <label>Category</label>
                //             <input readOnly={true} value={q.Category}/> 
                //         </div>
                //         <div>
                //             <label>Question</label>
                //             <textarea readOnly={true} value={q.Question}/> 
                //         </div>
                //         <div>
                //             <label>Alternative</label>
                //             <input readOnly={true} value={q.YourAnswerAlternative.Alternative}/> 
                //         </div>
                //         <div>
                //             <label>Answer</label>
                //             <textarea readOnly={true} value={q.YourAnswerAlternative.Answer}/> 
                //         </div>
                //         <div>
                //             <label>Question Number</label>
                //             <input readOnly={true} value={q.QuestionNo}/> 
                //         </div>
                //     </form>
                // </div>