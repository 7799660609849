import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export type QrCodeProps = {
    code: string
}

const ValidateQrCode = ({code}: QrCodeProps): JSX.Element => {
    console.log("Code " + code);
    const navigate = useNavigate();
    let { data } = useParams<"data">();
        
    useEffect(() => {
//read from localstorage if user is authenticated

//if not, go to login page

console.log("data: " + data);
//fetch the correct quiz based on what has been scanned
navigate(`/quiz/1000001/1/admin`)

// navigate(`/quiz/${code}/${username}`)

    })

    return <>Valid ot not valid for code: {code}</>
}

export default ValidateQrCode;