import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';
import { QuizDataAlternatives } from '../../../interfaces/quiz';
import { Row, Col } from "react-bootstrap";

type Props = {alternatives: QuizDataAlternatives[], quizNumber: number, showNextQuestion: () => void, answeredAlternative: (alternative: QuizDataAlternatives) => void}

const QuizQuestionButton = ({alternatives, quizNumber,showNextQuestion, answeredAlternative}: Props) => {
    const [quizNumber1, setQuizNumber] = useState<number>(quizNumber);
    // handleClick(){
    //     this.props.clickHandler(this.props.button_text)
    // }
    const handleOnClickAnswerButton = (alternative: QuizDataAlternatives) => {
        quizNumber = quizNumber+1
        showNextQuestion();
        answeredAlternative(alternative);
    }
        return (
            <Row fluid="true">
                {alternatives.map((alternative) => {
                return (
                    <Col key={alternative.Alternative} md={6} sm={6} xs={6} lg={6} xl={6} xxl={6} style={{paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px', paddingRight: '5px'}}>
                        <Button  style={{paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px', paddingRight: '5px'}} size="lg" className="btn-block" variant="primary" 
                                onClick={() => handleOnClickAnswerButton(alternative)}> 
                            {alternative.Alternative} - {alternative.Answer}
                        </Button>
                    </Col>
                )})}
            </Row>
        );
}

export default QuizQuestionButton;