
export interface QuizDataAlternatives {
    Number:number;
    Alternative: string;
    Answer: string;
    CorrectAnswer:boolean;
}

export enum QuestionType {
    Picture,
    Question
}


export interface QuizData {
    Id: string;
    QuestionNo: number;
    Category: string;
    Question: string;
    Alternatives: QuizDataAlternatives[];
    IsQuestionAnswered: boolean,
    YourAnswerAlternative?: string | undefined,
    isAnswerCorrect: boolean,
    Type: QuestionType,
    PicturePath?: string | undefined,
    IsQuizAlwaysOpen?: boolean,
    IsWeeklyQuiz?: boolean,
    FullDayOpenQuiz?: string | undefined, 
    QuizStartTime?: Date | undefined,
    QuizEndTime?: Date | undefined,
    QuizCost?: number | undefined,
    Currency?: string | undefined,
}

export interface Quiz {
Id: string;
QuizName: string;
Language: string;
Location: string;
City: string;
Country: string;
QuizData: QuizData[];
}

export interface AnsweredQuestion {
    QuizId: string,
    QuizName: string;
    Location: string;
    City: string;
    Country: string;
    QuestionId: string,
    QuestionNo: number,
    Category: string,
    Question: string,
    IsQuestionAnswered: boolean,
    YourAnswerAlternative: QuizDataAlternatives,
    PicturePath?: string | undefined,
    Type: QuestionType,
    IsAnswerCorrect?: boolean,
    Alternatives: QuizDataAlternatives[];
    }