import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import './App.css';
import { MenuProvider } from './state/Menu';
import Navbar from './components/navigation';
import Footer from './components/Footer';
import Home from './components/Home';
import QRScan from './components/QrReader/qr-reader-component';
import ValidateQrCode from './components/validation/validate-qr-code';
import Login from './components/authentication/login';
import LandingPage from './components/tipwalk/landingPage';
import Start from './components/tipwalk/startWithoutId';
import Quiz from './components/tipwalk/quiz/quiz';
import Profile from './components/user/profile';
import Register from './components/authentication/register';
import QuizSummary from './components/tipwalk/summary/quizSummary';
//import "bootstrap/dist/css/bootstrap.min.css";

const GlobalStyle = createGlobalStyle`
  ${reset};

  html {
    box-sizing: border-box;
  }

  body {
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg);
    color: var(--text);
  }

  * {
    margin: 0;
    padding: 0;
    padding-top:0;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  a {
    text-decoration: none;
  }
`;

const App = () => {
  return (
    <Router>
    <GlobalStyle />
    
    <MenuProvider>
          <Navbar />
        </MenuProvider>
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/home' element={<Home/>}/>
            <Route path='/scan' element={<QRScan/>}/>
            <Route path='/validate-code' element={<ValidateQrCode code={""}/>}/>
            <Route path='/start' element={<Start />}/>
            <Route path='/start/:quizid' element={<LandingPage />}/>
            <Route path='/quiz/*' element={<Quiz />}/>
            <Route path='/login' element={<Login email='' passwordProp='' hasError={false} errorMessage=''/>}/>
            <Route path='/profile' element={<Profile/>}/>
            <Route path='/register' element={<Register/>}/>
            <Route path='/summary/:quizid' element={<QuizSummary answeredQuestions={[]} quizId=""/>}/>
        </Routes>
      
      <Footer/>
    </Router>
  );
}

export default App;
