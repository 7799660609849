import React, { ChangeEvent, ChangeEventHandler, Component, FormEvent, useState } from "react";
//import axios from 'axios';
//import * as config from '../config';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//create a Register type--
// type Props = {
//     firstName:'',
//     lastName:'',
//     email:'',
//     password:'',
//     password2:'',
//     userName:''
// }

//https://www.youtube.com/watch?v=HPIjjFGYSJ4
//https://www.youtube.com/watch?v=CrAU8xTHy4M

const Register = () => {
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [userName, setUserName] = useState<string>("  ");
    const [hasError, setError] = useState<boolean>(false);
    
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {

    } 
    const onChangeFirstName = (event: ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    }
    const onChangeLastName = (event: ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    }
    const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }
    const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }
    const onChangePassword2 = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword2(event.target.value);
    }
    const onChangeUserName = (event: ChangeEvent<HTMLInputElement>) => {
        setUserName(event.target.value);
    }

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        console.log("Event is here: " + {event})
        // if (!this.state.password || this.state.password.length < 6) {
        //     return this.props.error('Password too short.'); //handle props
        //   }
        //   if (this.state.password !== this.state.password2) {
        //     return this.props.error('Passwords do not match.');
        //   }
        
        // //axios.post(config.SERVER_URL + "/user/register", {
        // //    firstName: this.state.firstName,
        // //    lastName:this.state.lastName,
        // //    email: this.state.email,
        // //    password: this.state.password,
        // //    userName:this.state.userName
        // //  }).then((rsp) => {
        // //    if (!rsp.data.status) {
        // //        console.log(rsp.data);
              
        // //        toast.error(rsp.data.message);
        // //    //   return this.props.error('Failed to create user.');
        // //    }
        // //    else{
        // //        toast.success(rsp.data.message);
        // //    }
        // //    // this.props.success('Created new user!');
        // //  });

        // // firstName = setPassword(event.target.value)
    }
        // this.onChange = this.onChange.bind(this)
        // this.onSubmit = this.onSubmit.bind(this)
        // this.state = { hasError: false };
        
    // }
    // onChange(e){
    //     this.setState({[e.target.name]: e.target.value})
    // }

    // componentDidCatch(error, info) {
    //     this.setState({hasError: true });
    // }

    const validateForm = () => {
        return firstName.length > 0 && lastName.length > 0 &&
           email.length > 0 && password.length > 0 &&
           password2.length > 0 && password2 === password &&
           userName.length > 0;

        return true;
    }

    return(
        <div className="container">
            <div className="row">
                <div className="col-md-6 mt-5 mx-auto">
                    <form noValidate onSubmit={onSubmit}> 
                        <ToastContainer />
                        <h1 className="h3 mb-3 front-weight-normal">Please register</h1>
                        <div className="form-group">
                            <label htmlFor="firstName">First name</label>
                            <input type="firstName"
                            className="form-control"
                            name="firstName"
                            placeholder="Enter First name"
                            value={firstName}
                            onChange={onChangeFirstName}
                            />
                        </div>                            
                        <div className="form-group">
                            <label htmlFor="lastName">Last name</label>
                            <input type="lastName"
                            className="form-control"
                            name="lastName"
                            placeholder="Enter last name"
                            value={lastName}
                            onChange={onChangeLastName}
                            />
                        </div>                           
                        <div className="form-group">
                            <label htmlFor="userName">Username</label>
                            <input type="userName"
                            className="form-control"
                            name="userName"
                            placeholder="Enter username"
                            value={userName}
                            onChange={onChangeUserName}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email Address</label>
                            <input type="email"
                            className="form-control"
                            name="email"
                            placeholder="Enter Email"
                            value={email}
                            onChange={onChangeEmail}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password"
                            className="form-control"
                            name="password"
                            placeholder="Enter password (minimum 6 characters)"
                            value={password}
                            onChange={onChangePassword}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Confirm password</label>
                            <input type="password"
                            className="form-control"
                            name="password2"
                            placeholder="Confirm password"
                            value={password2}
                            onChange={onChangePassword2}
                            />
                        </div>
                        <button type="submit"
                                className="btn btn-lg btn-primary btn-block"
                                disabled={!validateForm()}>
                            Register
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )

}

export default Register;