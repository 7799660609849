import React from 'react';
import "./css/Footer.css";

const Footer = (() =>  {
return (
    <div id="footer" className="row border-top text-muted footerPaddingTop">
        <div className="footerCentered footerPaddingRowTop">
            {/* <div>
                <p> Facebook: </p>
                <p> Twitter: </p>
            </div>
            <div >
                <p> Contact: </p>
            </div> */}
            <div >
                <p> &copy; {new Date().getFullYear()} Lindify </p>
                <p> All rights reserved </p>
            </div>        
        </div>
    </div>
    )}
);

export default Footer;